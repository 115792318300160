import React, { Fragment } from 'react';
import Navigation from './Navigation';
import Particles from 'react-particles-js';

const Landing = (props) => {
  const onSubmit = (e) => {
    e.preventDefault();
    props.aboutReference.current.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <Fragment>
      <section className='landing'>
        <Navigation
          theme={props.theme}
          setTheme={props.setTheme}
          aboutReference={props.aboutReference}
          linksReference={props.linksReference}
          projectsReference={props.projectsReference}
          workReference={props.workReference}
          techReference={props.techReference}
        ></Navigation>

        <div className='stars'>
          <Particles
            params={{
              particles: {
                number: {
                  value: 355,
                  density: {
                    enable: true,
                    value_area: 789.1476416322727,
                  },
                },
                color: {
                  value: '#ffffff',
                },
                shape: {
                  type: 'circle',
                  stroke: {
                    width: 0,
                    color: '#000000',
                  },
                  polygon: {
                    nb_sides: 5,
                  },
                  image: {
                    src: 'img/github.svg',
                    width: 100,
                    height: 100,
                  },
                },
                opacity: {
                  value: 0.48927153781200905,
                  random: false,
                  anim: {
                    enable: true,
                    speed: 0.2,
                    opacity_min: 0,
                    sync: false,
                  },
                },
                size: {
                  value: 2,
                  random: true,
                  anim: {
                    enable: true,
                    speed: 2,
                    size_min: 0,
                    sync: false,
                  },
                },
                line_linked: {
                  enable: false,
                  distance: 150,
                  color: '#ffffff',
                  opacity: 0.4,
                  width: 1,
                },
                move: {
                  enable: true,
                  speed: 0.2,
                  direction: 'none',
                  random: true,
                  straight: false,
                  out_mode: 'out',
                  bounce: false,
                  attract: {
                    enable: false,
                    rotateX: 600,
                    rotateY: 1200,
                  },
                },
              },
              interactivity: {
                detect_on: 'canvas',
                events: {
                  onhover: {
                    enable: true,
                    mode: 'bubble',
                  },
                  onclick: {
                    enable: true,
                    mode: 'push',
                  },
                  resize: true,
                },
                modes: {
                  grab: {
                    distance: 400,
                    line_linked: {
                      opacity: 1,
                    },
                  },
                  bubble: {
                    distance: 83.91608391608392,
                    size: 1,
                    duration: 3,
                    opacity: 1,
                    speed: 3,
                  },
                  repulse: {
                    distance: 200,
                    duration: 0.4,
                  },
                  push: {
                    particles_nb: 4,
                  },
                  remove: {
                    particles_nb: 2,
                  },
                },
              },
              retina_detect: true,
            }}
          />
        </div>

        <div className='intro'>
          <h1 className='header'>Hello, I'm Jacob.</h1>
          <a href='/#' className='btn' onClick={onSubmit}>
            Explore &nbsp; <i className='fa fa-arrow-down'></i>
          </a>
        </div>
      </section>
    </Fragment>
  );
};

export default Landing;
